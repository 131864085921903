@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-Black.woff2) format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-BlackItalic.woff2) format('woff2');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-ExtraBold.woff2) format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-ExtraBoldItalic.woff2) format('woff2');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-Bold.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-BoldItalic.woff2) format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-SemiBold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-SemiBoldItalic.woff2) format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-Medium.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-MediumItalic.woff2) format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-Regular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-RegularItalic.woff2) format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-Light.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-LightItalic.woff2) format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-ExtraLight.woff2) format('woff2');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-ExtraLightItalic.woff2) format('woff2');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-Thin.woff2) format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url(fonts/Metropolis-ThinItalic.woff2) format('woff2');
    font-weight: 100;
    font-style: italic;
}
